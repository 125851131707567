import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AuthoringDocumentFilter,
  Document,
  DocumentList,
  EmptyCard,
  EmptyDocument,
  Loading,
  NewDocumentForm,
} from "../../components";

import { useModal } from "../../context/ModalContext";
import { authoring, common } from "../../utills/static-text";

import useGetEquipments from "../../hooks/getEquipments";
import useGetQuestions from "../../hooks/getQuestions";
import { useAlert } from "../../context/AlertContext";

const Authoring = () => {
  const { treeId = "" } = useParams();
  const questions = useGetQuestions();

  const showModal = useModal();
  const showAlert = useAlert();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [documentProperty, setDocumentProperty] = useState();
  const [checkDecisionTree, setCheckDecisionTree] = useState(true);
  const [checkModelList, setCheckModelList] = useState(true);
  const type = "avaliable";
  const newDocumentEquipments = useGetEquipments(type);
  const filterByConf = useGetEquipments();
  const navigate = useNavigate()

  const filteredQueData = questions?.data?.filter(
    (item) =>
      (checkDecisionTree && item.doc_id === 1) ||
      (checkModelList && item.doc_id === 2)
  );

  const handelAddDocument = (event) => {
    event.preventDefault();
    showModal({
      className: "new-document-model",
      title: (
        <>
          <i className="fa-solid fa-file"></i> {common.new_document}
        </>
      ),
      body: (
        <>
          <NewDocumentForm filterByConf={newDocumentEquipments?.data} showAlert={showAlert} navigate={navigate} />
        </>
      ),
    });
  };

  const onDocumentUpdate = () => {
    // dispatch(getDocumentList());
    // TODO: need to check it again

    try {
      questions.refetch();
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <React.Fragment>
      <div className="wrapper">
        <section id="sidebar" className="sidebar authoring">
          <AuthoringDocumentFilter
            filterByConf={filterByConf?.data}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setCheckDecisionTree={setCheckDecisionTree}
            checkDecisionTree={checkDecisionTree}
            setCheckModelList={setCheckModelList}
            checkModelList={checkModelList}
            handelAddDocument={handelAddDocument}
          />
          {!_isEmpty(filteredQueData) ? (
            <Fragment>
              {checkDecisionTree || checkModelList ? (
                <DocumentList
                  filteredData={filteredQueData?.filter((item) =>
                    selectedFilter
                      ? selectedFilter?.prd_ln === item.prd_ln
                      : item
                  )}
                  setDocumentProperty={setDocumentProperty}
                />
              ) : (
                <EmptyCard
                  title={authoring.emptyFilter.title}
                  body={authoring.emptyFilter.body}
                />
              )}
            </Fragment>
          ) : filteredQueData && filteredQueData?.length === 0 ? (
            <EmptyCard
              title={authoring.emptyFilter.title}
              body={authoring.emptyFilter.body}
            />
          ) : (
            <Loading isIconOnly={true} />
          )}
        </section>
        <section className="main">
          {!treeId ? (
            <EmptyDocument
              {...{
                title: authoring.empty.tree.title,
                item_1: authoring.empty.tree.list.item_1,
                item_2: authoring.empty.tree.list.item_2,
                item_3: authoring.empty.tree.list.item_3,
                item_4: authoring.empty.tree.list.item_4,
              }}
            />
          ) : (
            <Document
              onDocumentUpdate={onDocumentUpdate}
              documentProperty={documentProperty}
              setDocumentProperty={setDocumentProperty}
            />
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default Authoring;
