import { useQueryClient } from '@tanstack/react-query';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { Fragment, useEffect, useState } from 'react';
import { Badge, Button, Col, Form, OverlayTrigger } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { GETooltip, Loading } from '..';
import { useAlert } from '../../context/AlertContext';
import { useModal } from '../../context/ModalContext';
import { updateEquipmentData } from '../../store/actions/configuration.actions';
import { common, configuration } from '../../utills/static-text';
import { debounce, trimString } from '../../utills/utills';

const FormEquipment = ({ equipmentData }) => {
  const showModal = useModal();
  const dispatch = useDispatch();
  const [product, setProduct] = useState();
  const [originalProduct, setOriginalProduct] = useState();
  const [isFormDirty, setIsFormDirty] = useState(false); // To track whether the form data has changed
  const [inputTagValue, setInputTagValue] = useState('');
  const [tagValues, setTagValues] = useState([]);
  const queryClient = useQueryClient();
  const showAlert = useAlert();

  useEffect(() => {
    const preUpdateInfo = () => {
      const { decision_tree_count, models_list_count } =
        equipmentData?.data?.product || {};
      setTagValues(
        trimString(equipmentData?.data?.product?.tags)
          ? equipmentData?.data?.product?.tags?.split(',')
          : []
      );
      return {
        ...equipmentData?.data?.product,
        tags: trimString(equipmentData?.data?.product?.tags)
          ? equipmentData?.data?.product?.tags?.split(',')
          : [],
        decision_tree_count: decision_tree_count,
        models_list_count: models_list_count,
      };
    };
    setProduct(preUpdateInfo());
    setOriginalProduct(preUpdateInfo());
  }, [equipmentData, equipmentData?.data?.product]);

  const handleReset = () => {
    // Reset the form values to the originalProduct
    setProduct({ ...originalProduct });
    setIsFormDirty(false);
    setTagValues([]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== 'tags') {
      setProduct({
        ...product,
        [name]: name === 'prd_ln' ? Number(value) : value,
      });
    }
    // Trigger the debounced function with the updated product
    handleDebouncedChange(product);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputTagValue.trim() !== '') {
        if (!tagValues.includes(inputTagValue.trim())) {
          setTagValues([...tagValues, inputTagValue.trim()]);
          setInputTagValue('');
          setProduct({
            ...product,
            tags: `${[...tagValues, inputTagValue.trim()].join(',')}`,
          });
        } else {
          showModal({
            title: 'Duplicate Tag!',
            body: `${inputTagValue.trim()} is a duplicate tag`,
          });
        }
        // Trigger the debounced function with the updated product
        handleDebouncedChange(product);
      }
    }
  };

  const handleRemove = (index) => {
    const newValues = [...tagValues];
    newValues.splice(index, 1);
    setTagValues(newValues);
    setProduct({
      ...product,
      tags: `${newValues.join(',')}`,
    });
    handleDebouncedChange(product);
  };

  const handleDebouncedChange = debounce((updatedProduct) => {
    // Compare with originalProduct and set isFormDirty
    setIsFormDirty(!_isEqual(updatedProduct, originalProduct));
  }, 500); // Adjust the debounce delay (in milliseconds) as needed

  const handleSubmit = (event) => {
    event.preventDefault();
    if (_isEmpty(product?.id) && isFormDirty) {
      dispatch(
        updateEquipmentData({
          body: {
            product: { ...product, tags: product?.tags.toString() },
          },
          method: 'POST',
        })
      ).then(() => {
        queryClient.invalidateQueries({
          queryKey: ['equipments'],
        });
        showAlert('Equipment is created', 'success');
      });
    } else {
      dispatch(
        updateEquipmentData({
          body: {
            product: { ...product, tags: product?.tags.toString() },
          },
          method: 'PUT',
        })
      ).then(() => {
        queryClient.invalidateQueries({
          queryKey: ['equipments'],
        });
        equipmentData.refetch();
        showAlert('Equipment is updated', 'success');
      });
    }
  };

  return (
    <Fragment>
      {equipmentData.isLoading ? (
        <Loading isIconOnly={true} />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Col} controlId="name">
            <Form.Label>{configuration.form.name}</Form.Label>
            <span className="text-danger">*</span>
            <Form.Control
              type="text"
              name="name"
              value={product?.name || ''}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="prd_ln" className="pt-3">
            <Form.Label>{configuration.form.prd_ln}</Form.Label>
            <span className="text-danger">*</span>{' '}
            <OverlayTrigger
              placement="right"
              overlay={GETooltip({
                msg: configuration.form.product_line_tooltip,
              })}
            >
              <i className="fa-solid fa-circle-info text-danger"></i>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="prd_ln"
              value={product?.prd_ln || 0}
              onChange={handleChange}
              required
              disabled={
                Number(product?.decision_tree_count) > 0 ||
                Number(product?.models_list_count) > 0
              }
            />
          </Form.Group>

          <Form.Group as={Col} controlId="description" className="pt-3">
            <Form.Label>{configuration.form.description}</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={product?.description || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="key" className="pt-3">
            <Form.Label>{configuration.form.key}</Form.Label>
            <span className="text-danger">*</span>
            <Form.Control
              type="text"
              name="key"
              value={product?.key || ''}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="tags" className="pt-3">
            <Form.Label>{configuration.form.tags}</Form.Label>{' '}
            <OverlayTrigger
              placement="right"
              overlay={GETooltip({
                msg: configuration.form.tags,
              })}
            >
              <i className="fa-solid fa-circle-info text-danger"></i>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="tags"
              value={inputTagValue}
              onChange={(e) => setInputTagValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Press Enter to add value"
            />
          </Form.Group>

          <div className="pt-2">
            {tagValues.map((value, index) => (
              <Badge
                className="fw-lighter"
                key={index}
                pill
                variant="primary"
                style={{ margin: '2px', cursor: 'pointer' }}
                onClick={() => handleRemove(index)}
              >
                {value && (
                  <>
                    {value} <span style={{ marginLeft: '5px' }}>x</span>
                  </>
                )}
              </Badge>
            ))}
          </div>

          <div className="py-3">
            <Button
              className="me-3 btn-primary"
              variant="primary"
              type="submit"
              disabled={!isFormDirty}
            >
              {common.btn_submit}
            </Button>

            <Button
              className="btn-danger text-white"
              variant="danger"
              type="button"
              onClick={handleReset}
              disabled={!isFormDirty}
            >
              {common.btn_reset}
            </Button>
          </div>
        </Form>
      )}
    </Fragment>
  );
};

export default FormEquipment;
