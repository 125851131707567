// import { useQueryClient } from "@tanstack/react-query";
import _isEmpty from "lodash/isEmpty";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loading, TagInput, TextEditor } from "../../components";
import { useAlert } from "../../context/AlertContext";
import useUpdateModelProperties from "../../hooks/updateModeListProperties";
import { updateTreeNodeData } from "../../store/actions/authoring.actions";
import { deployTreeData } from "../../store/actions/authoring.actions";
import useGetQuestions from "../../hooks/getQuestions";
import { useModal } from "../../context/ModalContext";
import { common } from "../../utills/static-text";
import { useTree } from "../../context/TreeContext";
import { trimString } from "../../utills/utills";

const DocumentProperty = ({ documentProperty, isDocumentLocked, handleRadioButtons,
  children, parentNode, documentData }) => {
  const { prdLn = "", treeId = "" } = useParams();
  const showAlert = useAlert();
  // const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [displayConfig, setDisplayConfig] = useState({});
  const [formValues, setFormValues] = useState({
    name: "",
    notes: "",
    tags: [],
    parts: [],
    user_base: ""
  });
  const [errors, setErrors] = useState({});
  const updateModelListProperties = useUpdateModelProperties();
  const documentList = useGetQuestions();
  const showModal = useModal();
  const { updateNode } = useTree();
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);
  const { profile } = useSelector((state) => state.user);
  useEffect(() => {
    // !isDocumentLocked && documentProperty?.doc_id === 1 ? setDisabled(true) : setDisabled(false)
    const currentUser =  profile?.email? profile?.email.toLowerCase() : profile?.nameID?.toLowerCase();
    if(isDocumentLocked && documentData?.data?.[0]?.locked_by === currentUser){
      setDisabled(false)
    }else{
      setDisabled(true)
    }
     
 // eslint-disable-next-line
  }, [isDocumentLocked, documentData]);

  useEffect(() => {
    // debugger;
    if (["internal", "external"].includes(parentNode?.user_base) && documentProperty?.parentOptionId !== null) {
      setIsOptionDisabled(true);
      setFormValues({
        ...formValues,
        user_base: parentNode?.user_base
      })
    }else if(parentNode?.user_base === "both" || documentProperty?.parentOptionId === null){
      setIsOptionDisabled(false);
    }
    // eslint-disable-next-line
  }, [documentProperty, parentNode])


  useEffect(() => {
    const quesType = documentProperty?.ques_type;
    const displayConfig = {
      name: documentProperty?.doc_id === 2 ? false : true,
      notes: quesType !== "option",
      parts: quesType === "solution",
      tags: documentProperty?.doc_id === 2 ? false : true,
      file: false,
      options: quesType === "option",
    };
    setDisplayConfig(displayConfig);
    if (documentProperty !== null && documentProperty !== undefined) {
      setFormValues({
        ...documentProperty,
        tags:
          documentProperty.tags !== null &&
          documentProperty.tags !== undefined &&
          trimString(documentProperty.tags),
        parts:
          documentProperty.parts !== null &&
          documentProperty.parts !== undefined &&
          trimString(documentProperty.parts),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentProperty]);

  const putDocumentDraft = () => {
    try {
      dispatch(
        deployTreeData({
          query: { env: "draft", prd_ln: prdLn, id: treeId },
        })
      );
      documentListRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseModal = () => {
    showModal(null);
    // navigate('/authoring');
    documentListRefetch();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    showModal({
      title: "Are you sure?",
      body: "The document will be move to stage for the review!.",
      footer: (
        <div>
          <Button
            variant="primary-outline"
            className="me-2"
            onClick={() => handleCloseModal()}
          >
            {common.btn_cancel}
          </Button>
          <Button variant="primary" className="me-2" onClick={formSubmit}>
            {common.btn_ok}
          </Button>
        </div>
      ),
    });
  };

  const formSubmit = () => {

    if (formValues.doc_id === 2) {
      updateModelListProperties.mutateAsync(
        {
          prd_ln: prdLn,
          id: formValues.id,
          notes: formValues.notes,
        },
        {
          onSuccess: () => {
            // Move to draft
            putDocumentDraft();

            // queryClient.invalidateQueries({
            //   queryKey: [''],
            // }); // TODO: Change this query key
            showAlert("Notes is updated!", "success");
            // documentListRefetch();
            updateNode(formValues.id, formValues);
          },
          onError: (error) => {
            showAlert(error.response.data.message, "danger");
          },
        }
      );
    } else {
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      setIsLoading(true);
      dispatch(
        updateTreeNodeData({
          query: {
            prd_ln: prdLn,
            id: formValues.id,
          },
          body: {
            id: formValues.id,
            ques_type: formValues.ques_type,
            ques_raw_content: formValues.raw_content,
            raw_content: formValues.raw_content,
            start_id: formValues.id,
            notes: formValues.notes,
            parts: formValues.parts,
            tags: formValues.tags,
            user_base: formValues.user_base,
            children: children
          },
          method: "PUT",
        })
      ).then(() => {
        // Move to draft
        putDocumentDraft();

        // queryClient.invalidateQueries({
        //   queryKey: ["treeData", prdLn],
        // });
        updateNode(formValues.id, formValues);
        setIsLoading(false);
        showAlert("Node is updated!", "success");
      });
    }

    handleCloseModal();
  };

  const validateForm = () => {
    let errors = {};
    if (!formValues.raw_content) {
      errors.raw_content = "Please enter document name";
    }
    return errors;
  };

  const handleInputChange = (key, value) => {
    if (
      key === "tags" &&
      value !== "" &&
      value !== null &&
      value !== undefined &&
      value !== false &&
      value !== true
    ) {
      setFormValues({
        ...formValues,
        [key]: value ? value : [],
      });
    } else if (
      key === "parts" &&
      value !== "" &&
      value !== null &&
      value !== undefined &&
      value !== false &&
      value !== true
    ) {
      setFormValues({
        ...formValues,
        [key]: value ? value : [],
      });
    } else {
      setFormValues({
        ...formValues,
        [key]: value,
      });
    }
  };


  const documentListRefetch = () => {
    try {
      documentList.refetch();
    } catch (e) {
      console.error("error", e);
    }
  }

  const handleChangeForChatbotRadio = (e) => {
    const { value, name } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    handleRadioButtons(value);
  }
  // console.log('children', children)
  return (
    <div className="row">
      <div className="col-12">
        {isLoading ? (
          <Loading isIconOnly={true} />
        ) : (
          <Fragment>
            <h5 className="mt-2 mb-3 text-capitalize">
              {documentProperty?.ques_type}
            </h5>

            {formValues && (
              <Form onSubmit={handleSubmit}>
                {displayConfig.options &&
                  <Form.Group className="mb-3">
                    <Form.Label>Userbase:</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        name="user_base"
                        label="Internal"
                        checked={formValues?.user_base === "internal"}
                        disabled={isOptionDisabled}
                        onChange={(e) => handleChangeForChatbotRadio(e)}
                        value={"internal"}
                        type="radio"
                      />
                      <Form.Check
                        inline
                        name="user_base"
                        label="External"
                        checked={formValues?.user_base === "external"}
                        disabled={isOptionDisabled}
                        onChange={(e) => handleChangeForChatbotRadio(e)}
                        value={"external"}
                        type="radio"
                      />
                      <Form.Check
                        inline
                        name="user_base"
                        label="Both"
                        checked={formValues?.user_base === "both"}
                        onChange={(e) => handleChangeForChatbotRadio(e)}
                        disabled={isOptionDisabled}
                        value={"both"}
                        type="radio"
                      />
                    </div>
                  </Form.Group>
                }
                {displayConfig.name && (
                  <Form.Group className="mb-3">
                    <TextEditor
                      value={formValues.raw_content}
                      onChange={(e) =>
                        handleInputChange("raw_content", e.target.value)
                      }
                      errors={errors}
                      disabled={disabled}
                    />
                  </Form.Group>
                )}
                {displayConfig.notes && (
                  <Form.Group className="mb-3">
                    <Form.Label>Notes</Form.Label>
                    <TextEditor
                      value={formValues.notes}
                      onChange={(e) =>
                        handleInputChange("notes", e.target.value)
                      }
                      errors={errors}
                      disabled={
                        disabled
                      }
                    />
                  </Form.Group>
                )}
                {displayConfig.tags && (
                  <Form.Group className="mb-3">
                    <Form.Label>Tags</Form.Label>
                    <TagInput
                      value={!_isEmpty(formValues.tags) ? formValues?.tags : ""}
                      onChange={(content) => handleInputChange("tags", content)}
                      disabled={disabled}
                    />
                  </Form.Group>
                )}
                {displayConfig.parts && (
                  <Form.Group className="mb-3">
                    <Form.Label>Parts</Form.Label>
                    <TagInput
                      value={
                        !_isEmpty(formValues?.parts) ? formValues?.parts : ""
                      }
                      onChange={(content) =>
                        handleInputChange("parts", content)
                      }
                      disabled={!isDocumentLocked}
                    />
                  </Form.Group>
                )}
                {displayConfig.file && (
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control type="file" disabled={true} />
                  </Form.Group>
                )}
                <div className="row">
                  <div className="col-12 mt-3 text-end">
                    <button
                      className="btn btn-muted me-2"
                      disabled={
                        disabled
                      }
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        disabled
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DocumentProperty;
