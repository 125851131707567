import React, { createContext, useContext, useState } from "react";
import { findParentNode, updateNodeChildren } from "../utills/utills";

// Create Context
const TreeContext = createContext();

// Recursive function to update a node in the tree
const updateNodeInTree = (tree, nodeId, updatedData) => {
   return tree.map(node => {
      if (node.id === nodeId) {
         return { ...node, ...updatedData }; // Update the node
      }
      if (node.children) {
         return { ...node, children: updateNodeInTree(node.children, nodeId, updatedData) };
      }
      return node;
   });
};

// Tree Provider Component
export const TreeProvider = ({ children }) => {
   const [treeData, setTreeData] = useState([])

   // Function to update a node in the tree
   const updateNode = (nodeId, updatedData) => {
      const preTree = [...treeData];
      const updateTreeData = updateNodeInTree(preTree, nodeId, updatedData)
      if (updatedData?.user_base) {
         const { id, user_base } = updatedData
         const clonedNodes = JSON.parse(JSON.stringify(updateTreeData));
         const clonedParentNode = findParentNode(clonedNodes, id);
         if (clonedParentNode) {
            // Update the selectOption of children of the given parentNode
            updateNodeChildren(clonedParentNode.children, user_base);
            setTreeData(clonedNodes);
         }
      } else {
         setTreeData(updateTreeData);
      }
   };

   const searchTree = (searchTerm) => {
      const results = {
          option: [],
          solution: [],
          question: []
      };
  
      const searchNode = (node) => {
          if (node.raw_content.toLowerCase().includes(searchTerm.toLowerCase())) {
              results[node.ques_type].push(node);
          }
          if (node.children) {
              node.children.forEach(child => searchNode(child));
          }
      };
  
      treeData.forEach(node => searchNode(node));
  
      return results;
  };

   return (
      <TreeContext.Provider value={{ treeData, updateNode, setTreeData, searchTree }}>
         {children}
      </TreeContext.Provider>
   );
};

// Custom hook to use the TreeContext
export const useTree = () => {
   return useContext(TreeContext);
};
