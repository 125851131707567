import React, { Fragment, useState } from "react";
import { Button, Dropdown, OverlayTrigger, SplitButton } from "react-bootstrap";
import { common } from "../../utills/static-text";
import { GETooltip, SVGComponent } from "../../components";
import { Form } from "react-bootstrap";

const DocumentHeader = ({
  documentData,
  isDocumentLocked,
  deploymentOptions,
  handleDeployment,
  handleDownload,
  handleUpload,
  handleDocumentIsEdit,
  handelDocumentClose,
  docType,
  showConfirmDeploy,
  onSearchText,
}) => {
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = () => {
    onSearchText(searchTerm);
    setSearching(true);
  };
  const handleClear = () => {
    setSearchTerm("");
    onSearchText("");
    setSearching(false);
  };

  return (
    <header className="authoring-header container-fluid">
      <div className="row authoring-header-row">
        {documentData?.data ? (
          <Fragment>
            <div className="col-auto d-sm-block">
              <h4
                className={`${
                  isDocumentLocked
                    ? "svg-document-lock" // text-document-lock
                    : "svg-secondary"
                }`}
              >
                <span className={`title-icon me-2`}>
                  {documentData?.data?.[0]?.doc_id === 1 ? (
                    <SVGComponent.DecisionTree />
                  ) : (
                    <SVGComponent.ModelList />
                  )}
                </span>

                {/* {isDocumentLocked && (
                  <span className={`title-icon me-2`}>
                    <SVGComponent.Lock />
                  </span>
                )} */}

                {documentData?.data?.[0]?.name}
              </h4>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <form
                className="d-flex input-group w-auto btn-group"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                {documentData?.data && (
                  <Fragment>
                    {documentData?.data?.[0]?.doc_id === 1 && (
                      <Fragment>
                        <Form.Control
                          type="text"
                          placeholder="Search..."
                          aria-label="Search"
                          value={searchTerm}
                          onChange={(e) => {
                            if (searching) {
                              handleClear();
                            }
                            setSearchTerm(e.target.value);
                          }}
                          className="tree-search"
                        />

                        <Button
                          variant="outline-primary"
                          onClick={searching ? handleClear : handleSearch}
                          className="btn-ico"
                        >
                          {searching ? (
                            <i className="fa fa-close"  id="searchIcon" aria-hidden="true"></i>
                          ) : (
                            <i className="fa fa-search" aria-hidden="true"></i>
                          )}
                        </Button>
                      </Fragment>
                    )}

                    <OverlayTrigger
                      placement="left"
                      overlay={GETooltip({
                        msg: deploymentOptions[0]?.msg,
                      })}
                    >
                      <SplitButton
                        key={`split-button${deploymentOptions[0]?.variant}`}
                        id={`dropdown-split-variants-${deploymentOptions[0]?.variant}`}
                        variant={deploymentOptions[0]?.variant?.toLowerCase()}
                        title={common.deploy_to}
                        disabled={deploymentOptions[0]?.disabled}
                      >
                        {deploymentOptions?.map((option, index) => (
                          <Dropdown.Item
                            key={`case-base-${index}`}
                            onClick={() => handleDeployment(option)}
                            className={`text-capitalize text-${deploymentOptions[0]?.variant?.toLowerCase()} fw-bold`}
                          >
                            {option.name}
                          </Dropdown.Item>
                        ))}
                      </SplitButton>
                    </OverlayTrigger>
                  </Fragment>
                )}
                {docType === "model" ? (
                  <Fragment>
                    <OverlayTrigger
                      placement="top"
                      overlay={GETooltip({
                        msg: common.btn_download,
                      })}
                    >
                      <Button
                        variant="outline-primary"
                        className={`btn btn-outline-${
                          isDocumentLocked ? "document-lock" : "primary"
                        }`}
                        type="button"
                        onClick={handleDownload}
                      >
                        {isDocumentLocked ? (
                          <i className="fa fa-unlock" aria-hidden="true"></i>
                        ) : (
                          common.btn_download
                        )}
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={GETooltip({
                        msg: common.btn_upload,
                      })}
                    >
                      <Button
                        variant="outline-primary"
                        className={`btn btn-outline-${
                          isDocumentLocked ? "document-lock" : "primary"
                        }`}
                        type="button"
                        onClick={handleUpload}
                      >
                        {isDocumentLocked ? (
                          <i className="fa fa-unlock" aria-hidden="true"></i>
                        ) : (
                          common.btn_upload
                        )}
                      </Button>
                    </OverlayTrigger>
                  </Fragment>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={GETooltip({
                      msg: isDocumentLocked ? "UNLOCKED, CLICK TO LOCK" : "LOCKED, UNLOCK TO EDIT",
                    })}
                  >
                    <Button
                      variant="outline-primary"
                      className={`btn btn-outline-${
                        isDocumentLocked ? "document-lock btn-ico" : "document-lock btn-ico"
                      }`}
                      type="button"
                      onClick={handleDocumentIsEdit}
                    >
                      {isDocumentLocked ? (
                          <i style={{color: "green"}} className="fa fa-unlock" aria-hidden="true"></i>
                      ) : (
                        <i style={{color: "red"}} className="fa fa-lock" aria-hidden="true"></i>
                      )}
                    </Button>
                  </OverlayTrigger>
                )}

                <OverlayTrigger
                  placement="top"
                  overlay={GETooltip({
                    msg: common.btn_close,
                  })}
                >
                  <Button
                    variant="outline-primary"
                    className={`btn btn-outline-${
                      isDocumentLocked ? "danger" : "primary"
                    }`}
                    type="button"
                    onClick={handelDocumentClose}
                  >
                    {common.btn_close}
                  </Button>
                </OverlayTrigger>
              </form>
            </div>
          </Fragment>
        ) : (
          <div>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </div>
        )}
      </div>
    </header>
  );
};

export default DocumentHeader;
