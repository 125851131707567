import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { headers } from "./headers";

const useGetEquipments = (type = "") => {
  const getEquipments = async () => {
    const baseURL = type ? `${process.env.REACT_APP_API_ORIGIN}/v1/api/equipments/${type}` :
      `${process.env.REACT_APP_API_ORIGIN}/v1/api/equipments`;
    const data = await axios.get(baseURL, { headers });
    return data?.data?.data;
  };

  return useQuery({
    queryFn: getEquipments,
    queryKey: ["equipments", type], // Include type in the queryKey
    refetchOnWindowFocus: false,

    retry: 3, // Retry the query up to 3 times in case of failure
    staleTime: 30000, // 30 seconds
    cacheTime: 5 * 60 * 1000, // 5 minutes

    onError: (error) => {
      console.error("Error fetching equipments:", error);
    },
  });
};

export default useGetEquipments;