import { useQueryClient } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Loading } from '..';
import { useModal } from '../../context/ModalContext';
import useCreateDocumentData from '../../hooks/createDocumentData';
import useCreateModelListData from '../../hooks/createModelListData';
import { authoring, common } from '../../utills/static-text';
import SampleCSVTable from './SampleCSVTable';
import UploadCSVInput from './UploadCSVInput';

const NewDocumentForm = ({ filterByConf, showAlert, navigate }) => {
  const [selectedEquipment, setSelectedEquipment] = useState('');
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [csvData, setCsvData] = useState({});
  const queryclient = useQueryClient();
  const [notes, setNotes] = useState('');
  const showModal = useModal();

  const [errors, setErrors] = useState({});
  const createDocumentData = useCreateDocumentData();
  const createModelListData = useCreateModelListData();

  const handleEquipmentSelect = (equipment) => {
    setSelectedEquipment(equipment);
  };

  const handleDocumentTypeSelect = (type) => {
    setSelectedDocumentType(type);
  };

  const validateForm = () => {
    let errors = {};
    // Check if prd_ln is empty
    if (!selectedEquipment) {
      errors.prd_ln = 'Please select target equipment';
    }

    // Check if doc_id is empty
    if (!selectedDocumentType) {
      errors.doc_id = 'Please select document type';
    }

    // Check if name is empty
    if (!documentName) {
      errors.name = 'Please enter document name';
    }

    // Clear error messages if all fields are filled
    if (
      selectedEquipment &&
      selectedEquipment.prd_ln &&
      selectedDocumentType &&
      documentName
    ) {
      setErrors({});
    }

    return errors;
  };
  const handleRefresh = () => {
    queryclient.invalidateQueries({
      queryKey: ['questions'],
    });
    queryclient.invalidateQueries({
      queryKey: ['equipments', "avaliable"],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateForm();

    // If there are errors, set the state and return
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Your form submission logic here
    const payload = {
      prd_ln: selectedEquipment,
      doc_id: selectedDocumentType,
      name: documentName,
      notes: notes,
      file: csvData,
    };
    try {
      const createDocumentRes = await createDocumentData.mutateAsync(payload);
      showAlert('Document created successfully', 'success');
      const { doc_id, id, prd_ln } = createDocumentRes?.data?.data;
      if (doc_id === '2') {
        await createModelListData.mutateAsync({
          ...payload,
          id,
          soft_delete: false
        })
        showAlert('CSV uploaded successfully', 'success');
      }
      showModal(null);
      setErrors({});
      handleRefresh();
      let url = doc_id === '2' ? `/authoring/model/${prd_ln}/1/${id}` : `/authoring/tree/${prd_ln}/1/${id}`;
      navigate(url);
    } catch (error) {
      showAlert('Document creation failed', 'danger');
    } finally {
      console.log('done')
    }
  };

  return (
    <Fragment>
      {createDocumentData.isLoading || createModelListData.isLoading ? (
        <Loading isIconOnly={true} />
      ) : (
        <Fragment>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>{authoring.newDocument.target_equipment}</Form.Label>
              <span className="text-danger">*</span>
              {/* <Dropdown>
              <Dropdown.Toggle
                variant="outline"
                className="border"
                id="dropdown-equipment"
              >
                {selectedEquipment
                  ? selectedEquipment.name
                  : authoring.newDocument.select_target_equipment}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {filterByConf.map((equipment) => (
                  <Dropdown.Item
                    key={`item-${equipment.id}`}
                    onClick={() => handleEquipmentSelect(equipment)}
                  >
                    {equipment.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}

              <Form.Select
                onChange={(e) => handleEquipmentSelect(e.target.value)}
              >
                <option value="">
                  {selectedEquipment
                    ? selectedEquipment.name
                    : authoring.newDocument.select_target_equipment}
                </option>

                {filterByConf.map((equipment) => (
                  <option
                    key={`item-${equipment.name.replace(/\s/g, '') ||
                      Math.floor(Math.random() * (100 - 99 + 1)) + 99
                      }`}
                    value={equipment.prd_ln}
                  >
                    {equipment.name}
                  </option>
                ))}
              </Form.Select>

              {errors.prd_ln && <p className="text-danger">{errors.prd_ln}</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{common.document_type}</Form.Label>
              <span className="text-danger">*</span>
              <Form.Select
                onChange={(e) => handleDocumentTypeSelect(e.target.value)}
              >
                <option value="">
                  {authoring.newDocument.select_document_type}
                </option>
                <option value="1">{common.decision_tree}</option>
                <option value="2">{common.model_list}</option>
              </Form.Select>
              {errors.doc_id && <p className="text-danger">{errors.doc_id}</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{authoring.newDocument.new_document_name}</Form.Label>
              <span className="text-danger">*</span>
              <Form.Control
                type="text"
                placeholder={common.name}
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
              />
              {errors.name && <p className="text-danger">{errors.name}</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{common.notes}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={common.notes}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>

            {selectedDocumentType === '2' && (
              <UploadCSVInput setCsvData={setCsvData} csvData={csvData} />
            )}

            {selectedDocumentType === '2' && <SampleCSVTable common={common} />}
            <div className="text-end">
              <Button variant="outline-secondary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NewDocumentForm;
