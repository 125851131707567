import React from 'react';
import { Item, Menu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';


const ContextMenuComponent = ({ id, ctxMenu, onContextMenuAction }) => {
  return <Menu id={id}>
    {ctxMenu.map((ctx, index) => (<Item key={index}
          onClick={() => onContextMenuAction(ctx.action)}
          className={ctx.hadBorderBottom ? 'border-bottom' : ''}
          disabled={ctx.disabled}
        >
          {ctx.item}
        </Item>
    ))}
  </Menu>
};

export default ContextMenuComponent;
