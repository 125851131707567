export const to = (promise) =>
  promise.then((data) => [null, data]).catch((err) => [err]);

export const throwError = (errMessage, log) => {
  if (log === true) return errMessage;
};

export const getAPIQuery = (query) => {
  let params = '/';
  if (query) {
    for (let key in query) {
      params += `${query[key]}/`;
    }
  }

  return params.substring(0, params.length - 1);
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const findTreeNodeById = (objArray, targetId, type) => {
  if (objArray) {
    for (let obj of objArray) {
      if (obj.id === targetId && obj?.ques_type === type) {
        return obj;
      }
      if (obj.children && obj.children.length > 0) {
        const foundInChildren = findTreeNodeById(obj.children, targetId);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
  }
  return null;
};

export const searchTree = (tree, query, parentId = null, depth = 0) => {
  let result = null;

  for (const node of tree) {
    if (node.id === query) {
      result = { ...node, depth };
      break;
    }

    if (node.children && node.children.length > 0) {
      const childResult = searchTree(node.children, query, node.id, depth + 1);
      if (childResult) {
        result = childResult;
        break;
      }
    }
  }

  return result;
};

export const renderHtmlContent = (htmlString) => {
  return { __html: htmlString };
};

// Helper function to update a node in the tree recursively
export const updateNode = (nodes, updatedNode) => {
  return nodes.map((node) => {
    if (node.id === updatedNode.id) {
      return { ...node, ...updatedNode };
    } else if (node.children) {
      return { ...node, children: updateNode(node.children, updatedNode) };
    } else {
      return node;
    }
  });
};

export const trimString = (input) => {
  if (typeof input === 'string' && input.trim() !== '') {
    return input.trim();
  }
  return input;
};

export const findParentNode = (nodes, parentOptionId) => {
  for (let node of nodes) {
    if (node.id === parentOptionId) {
      return node;
    } else if (node.children && node.children.length > 0) {
      const result = findParentNode(node.children, parentOptionId);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

// Function to recursively get all children options for a given node
export const getAllChildrenOptions = (node, user_base) => {
  console.log('user_base', user_base)
  let options = [];
  if (node.ques_type === 'option' && node.parentOptionId !== null) {
    options.push({
      id: node?.id,
      user_base: user_base
    });
  }
  if (node.children && node.children.length > 0) {
    for (let child of node.children) {
      options = options.concat(getAllChildrenOptions(child, user_base));
    }
  }
  return options;
};

export const updateNodeChildren = (children, newSelectOption) => {
  for (let child of children) {
    if (child.ques_type === 'option') {
      child.user_base = newSelectOption;
    }
    if (child.children && child.children.length > 0) {
      updateNodeChildren(child.children, newSelectOption);
    }
  }
};

export const findNodeAndParents = (node, targetId, path = []) => {
  if (node.id === targetId) {
    return { foundNode: node, parents: path };
  }
  if (node.children) {
    for (const child of node.children) {
      const result = findNodeAndParents(child, targetId, [...path, node]);
      if (result) return result;
    }
  }
  return null;
};
